<template>
  <section :class="['rankingModel', isLoading && 'skeletonS']">
    <template v-if="!isLoading">
      <div class="rankingModel__avatarContainer" :stateAvatar="state">
        <Avatar class="rankingModel__avatar" :user="data" />
        <span class="rankingModel__statusCircle" :stateAvatar="state"></span>
      </div>
      <div class="rankingModel__contentInfo">
        <p class="rankingModel__userName">{{ userName }}</p>
        <p class="rankingModel__location">{{ `${data.cityName} - ${data.officeName}` }}</p>
      </div>
      <h3 class="rankingModel__hours">{{ hoursWork + " H" }}</h3>
    </template>
  </section>
</template>

<script>
export default {
  props: ["isLoading", "data"],

  computed: {
    state() {
      let { isActive, isOnline } = this.data;
      return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    },
    userName() {
      return this.data?.artisticName || this.data?.user;
    },
    hoursWork() {
      const minutes = this.data.minutes;
      const convertInHours = minutes / 60;
      return isNaN(convertInHours) ? 0 : Math.round(convertInHours);
    },
  },
};
</script>

<style lang="scss">
.rankingModel {
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: 35px auto 60px;
  gap: 10px;
  height: 48px;
  width: 100%;
  padding: 3px 10px;
  border-radius: 50px;
  background: #f3f3f3;
  &__avatarContainer {
    position: relative;
    width: fit-content;
    border: 3px solid $alto;
    border-radius: 50%;
    &[stateAvatar="online"] {
      border-color: $la_palma;
    }
    &[stateAvatar="away"] {
      border-color: $tree_Poppy;
    }
  }
  &__avatar {
    width: 30px;
    height: 30px;
    font-size: 18px;
    border: 2px solid $white;
  }
  &__statusCircle {
    position: absolute;
    width: 8px;
    height: 8px;
    right: 0;
    bottom: 0;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $alto;
    &[stateAvatar="online"] {
      background-color: $la_palma;
    }
    &[stateAvatar="away"] {
      background-color: $tree_Poppy;
    }
  }
  &__userName {
    font-size: 16px;
  }
  &__location {
    font-size: 13px;
    color: $chicago;
  }
  &__hours {
    text-align: end;
    font-size: 17px;
  }
  @media screen and (min-width: $tablet_width) {
    padding: 3px 16px;
  }
}
</style>
